import { useEffect, useState } from "react";
import { Box, Typography, Card, Grid2 } from "@mui/material";
import { styled } from "@mui/system";

const ProgressBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#60606040", // Changed color code to proper hex with #
  width: 44,
  height: 100,
  borderRadius: 4,
  position: "relative",
  overflow: "hidden",
  display: "flex",
  alignItems: "flex-end",
}));

export default function AdminBody(props) {
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [days, setDays] = useState(0);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, []);

  useEffect(() => {
    if (props.item) {
      let c = 0;
      if (props.item.Received) c += 1;
      if (props.item.Checking) c += 1;
      if (props.item.ReportSubmitted) c += 1;
      if (props.item.Assigned) c += 1;
      setData(c);
      if (!props.item?.ReportSubmitted) {
        const pastDate = new Date(props.item?.createdAt);
        pastDate.setDate(pastDate.getDate() + 5);
        const currentDate = new Date();
        pastDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        const timeDifference = pastDate.getTime() - currentDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        setDays(Math.round(daysDifference));
      } else {
        setDays(0);
        setData(4);
      }
    }
  }, [props.item]);

  function formatAMPM(date) {
    let hours = date?.getHours();
    let minutes = date?.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  return (
    <Card
      onClick={() => {
        window.location.href = "/requests/admin/" + props.item.DocumentID;
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        marginBottom: 2,
        boxShadow: "0px 8px 16px rgba(96, 96, 96, 0.25)",
        cursor: "pointer",
      }}
    >
      {/* Date Section */}
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          textAlign: "center",
          padding: 1,
          borderRadius: 1,
          marginRight: 2,
        }}
      >
        <Typography variant="h6">{date?.getDate()}</Typography>
        <Typography variant="body2">
          {monthNames[date?.getMonth()]} {date?.getFullYear()}
        </Typography>
        <Typography variant="body2">{formatAMPM(date)}</Typography>
      </Box>

      {/* Details Section */}
      <Grid2 container spacing={2} flex={1}>
        <Grid2 size={6}>
          <Typography variant="h6">{props.item.Title}</Typography>
          <Typography variant="body2">
            Organization: {props.item.Organisation}
          </Typography>
          <Typography variant="body2">
            Contact Person: {props.item.Name}
          </Typography>
        </Grid2>
        <Grid2 size={3}>
          <Typography variant="body2">
            Subjects: {props.item.Clients ? props.item.Clients.length : 0}
          </Typography>
        </Grid2>
        {/* Deadline Section */}
        <Grid2 size={3} textAlign="right">
          <Typography
            variant="h6"
            color={days < 0 ? "error.main" : "success.main"}
          >
            {days} Day{Math.abs(days) === 1 ? "" : "s"}
          </Typography>
          <Typography variant="body2">
            Assigned To: <b>{props.item?.Assigned || "Not Assigned!"}</b>
          </Typography>
        </Grid2>
      </Grid2>

      {/* Progress Section */}
      <ProgressBox sx={{ marginLeft: 2 }}>
        <Box
          sx={{
            height: data ? `${((data / 4) * 100).toFixed(0)}%` : "0%",
            width: "100%",
            backgroundColor: "primary.main",
            borderRadius: "4px 4px 0 0",
            transition: "height 0.3s ease",
          }}
        />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            position: "absolute",
            bottom: 4,
            textAlign: "center",
            width: "100%",
          }}
        >
          {((data / 4) * 100).toFixed(0)}%
        </Typography>
      </ProgressBox>
    </Card>
  );
}
