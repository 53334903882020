import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { BsDownload, BsEmojiSmile } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { MdAddTask } from "react-icons/md";
import "../../Styles/tasks.scss";
import CustomBarChart from "../Stats/CustomBarChart";
import AccordionBody from "../Util/AccordionBody";
import MyError from "../Util/MyError";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import doc from "../../assets/Files/Report Format.docx";
export default function Tasks(props) {
  var jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(true);
  const [active, setActive] = useState("Active Tasks");
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [id, setId] = useState(null);
  const [user, setUser] = useState("");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setId(decoded.UserID);
          setUser(decoded.Name);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetch(`/api/requests/stats/top/${id}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [id, refresh]);

  useEffect(() => {
    if (!id) return;
    setTasks(null);
    if (active === "Active Tasks") {
      setLoading(true);
      fetch(`/api/requests/paginated/Assigned/${offset * 12}/${id}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/requests/paginated/Complete/${offset * 12}/${id}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh, id]);

  const handleDownload = () => {
    const fileContent =
      require("../../assets/Files/Report Format.docx").default;

    // Create a Blob object from the file content
    const blob = new Blob([fileContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Report Template.docx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="tasks">
      <div className="welcome">
        <div>
          <h1>Welcome Back, {user}</h1>
          <p>
            Let's do our best today <BsEmojiSmile color="orange" />
          </p>
        </div>
        <p
          className="dw"
          onClick={() => {
            handleDownload();
          }}
        >
          {" "}
          <FontAwesomeIcon className="ic" icon={faDownload} />
          Report Template
        </p>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>

      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>My Tasks</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <MdAddTask className="ts" />
                  <div>
                    <h4>{data ? data?.AllTasks : 0}</h4>
                    <p>All Tasks</p>
                    <p>
                      Completed: <b>{data ? data?.Complete : 0}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="gauge">
                  <h3>Completion Rate</h3>
                  <GaugeChart
                    className="gg"
                    id="gauge-chart2"
                    nrOfLevels={3}
                    colors={["red", "orange", "green"]}
                    arcWidth={0.3}
                    percent={
                      data ? (data?.Complete / data?.AllTasks).toFixed(2) : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.8}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <div className="hl">
                  <h4>{data ? data?.Overdue : 0}</h4>
                  <h5>Overdue</h5>
                </div>
                <h2>Task Due Date</h2>
                <div className="div1auto">
                  <div style={{ backgroundColor: "red" }}></div>
                  <p>1 Day:</p>
                  <h6>{data ? data?.Day1 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "orange" }}></div>
                  <p>2 Days:</p>
                  <h6>{data ? data?.Day2 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "green" }}></div>
                  <p> 3 or More Days:</p>
                  <h6>{data ? data?.Day3 : 0}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="selector">
        <div className="cont">
          <Selector txt="Active Tasks" active={active} setActive={setActive} />
          <Selector
            txt="Completed Tasks"
            active={active}
            setActive={setActive}
          />
        </div>
      </div>

      <div className="tcontent">
        {tasks && tasks?.data?.length > 0 ? (
          tasks?.data?.map((item, index) => {
            return (
              <AccordionBody
                item={item}
                key={index}
                task={true}
                refresh={refresh}
                setRefresh={setRefresh}
                type="Assigned"
                date={item.createdAt.split("T")[0]}
                description={
                  item.Description.length > 30 ? item.Description : ""
                }
                file={item.File}
              />
            );
          })
        ) : (
          <MyError txt="No Active Tasks" />
        )}
        {tasks && tasks?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(tasks.total / 12)}
            currentPage={offset + 1}
            handlePageChange={(v) => setOffset(v - 1)}
          />
        )}
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}

const Selector = (props) => {
  return (
    <div className={props.active === props.txt ? "active" : "item"}>
      <div></div>
      <h3
        onClick={() => {
          props.setActive(props.txt);
        }}
      >
        {props.txt}
      </h3>
    </div>
  );
};
