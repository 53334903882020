import {
  faAddressBook,
  faArrowLeftLong,
  faAt,
  faDownload,
  faEnvelope,
  faPhone,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Watermark } from "@hirohe/react-watermark";
import "../../Styles/payroll.scss";
import logo from "../../assets/images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";

export default function Payslip(props) {
  const [data, setData] = useState(null);
  const [payData, setPayData] = useState(null);
  const [payslips, setPayslips] = useState(null);
  const [offset, setOffset] = useState(0);
  const [error, setError] = useState(null);
  const id = window.location.pathname.split("/")[3];
  const month = window.location.pathname.split("/")[4];
  const year = window.location.pathname.split("/")[5];
  const [monthname, setMonthname] = useState(null);

  useEffect(() => {
    fetch(`/api/employees/employeeid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.length > 0) setData(data[0]);
      })
      .catch((e) => {});
    fetch(`/api/payslip/employeeid/${id}/${month}/${year}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.length > 0) {
          setPayData(data[0]);
          const date = new Date(data[0].Date);
          // Get month name
          const monthName = date.toLocaleString("default", { month: "long" });
          setMonthname(monthName);
        }
      })
      .catch((e) => {});
  }, [id, month, year]);

  useEffect(() => {
    fetch(`/api/payslip/paginated/${id}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setPayslips(data);
      })
      .catch((e) => {});
  }, [id, offset]);

  const downloadPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = pdfWidth;
        let footerImgHeight = pdfWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        pdf.save(`${data?.Name}.pdf`);
      });
    });
  };

  const sendPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = pdfWidth;
        let footerImgHeight = pdfWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        const blb = pdf.output(`blob`);
        sendData(blb, `${data.Name}-${month}-${year}`);
      });
    });
  };

  function sendData(pdf, downloadFileName) {
    const formData = new FormData();
    formData.append("Payslip", pdf, `${downloadFileName}.pdf`);
    formData.append("Email", data?.Email);
    formData.append("Name", data?.Name);
    formData.append("Month", monthname ? monthname : month);
    formData.append("Year", year);

    fetch(`/api/payslip/sendemail/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            setError(null);
          }, 1000);
        } else {
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 1000);
        }
      })
      .catch((e) => {});
  }

  return (
    <div className="payroll">
      <div className="payslip">
        <div className="top">
          <h2>{data?.Name} Payslip</h2>

          <button
            onClick={() => {
              window.location.href = "/payroll";
            }}
          >
            <FontAwesomeIcon icon={faArrowLeftLong} />
            Back
          </button>
        </div>
        <div className="divsplit">
          <div className="inv_preview">
            <div id="invoice" className="invoice">
              <div className="bar">
                <div className="invtop">
                  <img src={logo} alt="" />
                  <br />
                  <br />
                </div>
                <h1>Payslip</h1>
                <div className="div"></div>
              </div>
              <div className="invheader">
                <div className="left">
                  <div className="enclose">
                    <h4>Employee Details:</h4>
                    <hr />
                    <div className="div1auto">
                      <h4>Name: </h4>
                      <p> {data?.Name}</p>
                    </div>
                    <div className="div1auto">
                      <h4>Empoyee No.: </h4>
                      <p> {data?.EmployeeNo}</p>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="div1auto">
                    <h4>Due Date:</h4>
                    <p>{formatDate(payData?.Date)}</p>
                  </div>
                  <div className="div1auto">
                    <h4>Paid Date:</h4>
                    <p>{formatDate(payData?.createdAt)}</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="tphead">
                  <h3>Item</h3>
                  <h3>Total (KSh)</h3>
                </div>
                <div className="div2equal">
                  <h4>Gross Pay</h4>
                  <p>
                    {payData?.Gross.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>Bonus </h4>
                  <p>
                    {payData?.Bonus.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h3>Total Earnings</h3>
                  <p>
                    {(payData?.Gross + payData?.Bonus).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>PAYE</h4>
                  <p>
                    {payData?.PAYE.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>NHIF</h4>
                  <p>
                    {payData?.NHIF.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>NSSF</h4>
                  <p>
                    {payData?.NSSF.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>HELB</h4>
                  <p>
                    {payData?.HELB.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>Loan deductions</h4>
                  <p>
                    {payData?.Loan.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>Company Pension Scheme</h4>
                  <p>
                    {payData?.Pension.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>Housing Levy</h4>
                  <p>
                    {payData?.HousingLevy.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h4>Other Deductions </h4>
                  <p>
                    {payData?.Other.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="div2equal">
                  <h3>Total Deductions</h3>
                  <p>
                    {(
                      payData?.Loan +
                      payData?.HELB +
                      payData?.Pension +
                      payData?.PAYE +
                      payData?.HousingLevy +
                      payData?.Other +
                      payData?.NSSF +
                      payData?.NHIF
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>

              <div className="totals">
                <div className="div1auto">
                  <div>
                    <h4>Thank you for your business</h4>
                    <br />
                    <p>
                      This is a system generated payslip. No signature or stamp
                      is required.
                    </p>
                  </div>
                  <div>
                    <div className="tdiv tt">
                      <h4>Net Pay</h4>{" "}
                      <p>
                        {" "}
                        {payData?.NetSalary.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="invfooter">
              <div className="fbars">
                <div></div>
                <h6>Arope Group LTD</h6>
                <div></div>
              </div>
              <div className="contacts">
                <p>
                  <FontAwesomeIcon icon={faUserAlt} /> Victoria Mulwa
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} />
                  +254 725 214 688
                </p>
                <p>
                  <FontAwesomeIcon icon={faAddressBook} />{" "}
                  victoria@aropegroup.com
                </p>
              </div>
            </div>
          </div>
          <div className="iright_panel">
            <div className="links">
              <button
                onClick={() => {
                  sendPdfDocument();
                }}
              >
                <FontAwesomeIcon className="ic" icon={faEnvelope} />
                {payData && payData.Payslip ? "Send Again" : "Send Payslip"}
              </button>
              {error && <h6>{error}</h6>}
              <button
                onClick={() => {
                  downloadPdfDocument();
                }}
              >
                <FontAwesomeIcon className="ic" icon={faDownload} /> Download
                Payslip
              </button>
            </div>
            <div className="c_payments">
              <h3>Payments</h3>
              <hr />
              <div>
                {payslips &&
                  payslips.data.length > 0 &&
                  payslips.data.map((item, i) => {
                    return (
                      <PayslipItem
                        key={i}
                        item={item}
                        index={i}
                        id={id}
                        year={year}
                        month={month}
                      />
                    );
                  })}
              </div>
              <br />
              <Pagination
                totalPages={payslips ? payslips.total : 1}
                currentPage={offset + 1}
                handlePageChange={(v) => setOffset(v - 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PayslipItem = (props) => {
  const [date, setDate] = useState("January, 2024");
  const dd = props.item.Date.split("T")[0].split("-");

  useEffect(() => {
    const date = new Date(props.item.Date);
    // Get month name
    const monthName = date.toLocaleString("default", { month: "long" });
    // Get year
    const year = date.getFullYear();
    setDate(`${monthName}, ${year}`);
  }, []);

  return (
    <div
      style={{
        backgroundColor:
          dd[0] == props.year && dd[1] == props.month ? "wheat" : "white",
      }}
      onClick={() => {
        window.location.href = `/payroll/payslip/${props.id}/${dd[1]}/${dd[0]}`;
      }}
      className="pitem"
    >
      <p className="no">{date}</p>
      <div>
        <h4>
          GP:{" "}
          {props?.item?.Gross.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </h4>
        <p>Paid: {new Date(props?.item?.createdAt).toLocaleDateString()}</p>
      </div>

      <h5>
        {(props?.item?.NetSalary - 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h5>
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
