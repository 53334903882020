import { useRef, useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  TextField,
  Grid2,
  Divider,
  Button,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowRight,
  faList,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

export default function NewInvoice(props) {
  const [loading, setLoading] = useState(false);
  const [createItem, setCreateItem] = useState(false);
  const [listItem, setListItem] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [total, setTotal] = useState(0.0);
  const [subtotal, setSubTotal] = useState(0.0);
  const [vatStatus, setVATStatus] = useState(false);
  const [vat, setVAT] = useState(0.0);
  const [currency] = useState("KSh");
  const [bdata, setBdata] = useState([]);
  const title = useRef();
  const date = useRef();

  useEffect(() => {
    let sbt = bdata.reduce(
      (sum, item) => sum + (item?.Rate || 0) * (item.Qty || 0),
      0
    );
    setSubTotal(sbt);
    if (vatStatus) {
      const vatAmount = sbt * 0.1;
      setVAT(vatAmount);
      setTotal(sbt + vatAmount);
    } else {
      setVAT(0);
      setTotal(sbt);
    }
  }, [bdata, vatStatus]);

  const searchByPhone = (q) => {
    fetch(`/api/mobile/seachbyname/${q}`)
      .then((res) => res.json())
      .then((data) => setFO(data))
      .catch(() => setFO(null));
  };

  const createDocument = () => {
    if (!bdata.length) return setError("You must add an invoice item!");
    const body = {
      Title: title.current.value,
      Date: date.current.value,
      ItemsList: bdata,
      SubTotal: subtotal,
      VAT: vat,
      Total: total,
    };

    fetch("/api/invoices/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          window.location.href = `/invoices/preview/${data.id}`;
        } else setError(data.error);
      })
      .catch(() => setError("Oops! Something went wrong!"));
  };

  return (
    <Box p={2}>
      <Card sx={{ p: 2, mb: 2, boxShadow: "0px 4px 8px #60606040" }}>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="h5">Create Invoice</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }} display="flex" justifyContent="flex-end">
            <Button
              startIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={() => props.setCreating(false)}
              variant="outlined"
            >
              Invoices
            </Button>
          </Grid2>
        </Grid2>
      </Card>

      <Card sx={{ p: 2, boxShadow: "0px 4px 8px #60606040" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="h6">Search Customer</Typography>
            <Divider sx={{ mb: 1 }} />
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              onChange={(e) => searchByPhone(e.target.value)}
            />
            {fo && (
              <Box
                mt={2}
                sx={{ border: "1px solid #ddd", p: 1, borderRadius: 1 }}
              >
                {fo.map((item, index) => (
                  <SOList
                    key={index}
                    item={item}
                    setCustomer={setFOData}
                    clearEvent={() => {
                      setFO(null);
                    }}
                  />
                ))}
              </Box>
            )}
            {fodata && (
              <SOItem
                item={fodata}
                clearEvent={() => {
                  setFOData(null);
                }}
              />
            )}
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="h6">Invoice Details</Typography>
            <Divider sx={{ mb: 1 }} />
            <TextField
              fullWidth
              label="LPO/LSO/Title *"
              inputRef={title}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Due Date *"
              type="date"
              inputRef={date}
              variant="outlined"
              defaultValue={new Date().toISOString().split("T")[0]}
              sx={{ mt: 2 }}
            />
          </Grid2>
        </Grid2>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Billing Items
        </Typography>
        <Divider sx={{ mb: 1 }} />
        <Box sx={{ mb: 2, backgroundColor: "#f9ecee", p: 1, borderRadius: 1 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 4 }}>
              <Typography>Item</Typography>
            </Grid2>
            <Grid2 size={{ xs: 1 }}>
              <Typography>Qty</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography>Rate (KSh)</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography>Total (KSh)</Typography>
            </Grid2>
            <Grid2 size={{ xs: 1 }}>
              <Typography>Action</Typography>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 2 }} />
          {bdata.map((item, i) => (
            <BillingItem
              key={i}
              item={item}
              bdata={bdata}
              setBdata={setBdata}
            />
          ))}
        </Box>

        <Box display="flex" gap={1} sx={{ mb: 2 }}>
          <IconButton color="primary" onClick={() => setCreateItem(true)}>
            <FontAwesomeIcon icon={faAdd} />
          </IconButton>
          <IconButton color="primary" onClick={() => setListItem(true)}>
            <FontAwesomeIcon icon={faList} />
          </IconButton>
        </Box>

        <Box mt={3}>
          <Typography textAlign="right" variant="subtitle">
            Sub Total ({currency}): {subtotal.toFixed(2)}
          </Typography>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={vatStatus}
              onChange={(e) => setVATStatus(e.target.checked)}
            />
            <Typography variant="subtitle">
              VAT Inclusive (16%) ({currency}): {vat.toFixed(2)}
            </Typography>
          </Box>
          <Typography variant="subtitle">
            Total ({currency}): {total.toFixed(2)}
          </Typography>
        </Box>

        {error && <Typography color="error">{error}</Typography>}

        <Button
          onClick={createDocument}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Card>

      <CreateBillingItemDialog
        open={createItem}
        setOpen={setCreateItem}
        bdata={bdata}
        setBdata={setBdata}
      />
      <BillingListDialog
        open={listItem}
        setOpen={setListItem}
        bdata={bdata}
        setBdata={setBdata}
      />
    </Box>
  );
}

// Helper components
function SOList({ item, setCustomer, clearEvent }) {
  return (
    <Box
      onClick={() => {
        setCustomer(item);
        clearEvent();
      }}
      sx={{ p: 1, cursor: "pointer", borderBottom: "1px solid #ddd" }}
    >
      <Typography variant="subtitle1">{item.Organisation}</Typography>
      <Typography variant="body2">
        <b>Contact:</b> {item.Name} - {item.Email}
      </Typography>
    </Box>
  );
}

function SOItem({ item, clearEvent }) {
  return (
    <Box
      mt={2}
      sx={{
        border: "1px solid #ddd",
        p: 2,
        borderRadius: 1,
        position: "relative",
      }}
    >
      <Typography variant="subtitle1">{item.Organisation}</Typography>
      <Typography variant="body2">
        <b>Contact:</b> {item.Name} - {item.Email}
      </Typography>
      <IconButton
        onClick={clearEvent}
        color="error"
        size="small"
        sx={{ mt: 1, position: "absolute", top: 1, right: 2 }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
    </Box>
  );
}

function BillingItem({ item, bdata, setBdata }) {
  const handleDelete = () => {
    const updatedBdata = bdata.filter((_, i) => i !== bdata.indexOf(item));
    setBdata(updatedBdata);
  };

  return (
    <Grid2
      container
      spacing={1}
      alignItems="center"
      sx={{ p: 1, borderBottom: "1px solid #ddd" }}
    >
      <Grid2 size={{ xs: 4 }}>
        <Typography>{item.Item}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <Typography>{item?.Qty}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <Typography>{item?.Rate}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <Typography>{(item?.Rate * item?.Qty).toFixed(2)}</Typography>
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <IconButton onClick={handleDelete} color="error">
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Grid2>
    </Grid2>
  );
}

function CreateBillingItemDialog({ open, setOpen, bdata, setBdata }) {
  const item = useRef();
  const qty = useRef();
  const rate = useRef();
  const [error, setError] = useState("");

  const addBillingItem = () => {
    if (!item.current.value || !qty.current.value || !rate.current.value) {
      return setError("All fields are required!");
    }
    const newItem = {
      Item: item.current.value,
      Qty: parseInt(qty.current.value),
      Rate: parseFloat(rate.current.value),
    };
    setBdata([...bdata, newItem]);
    setOpen(false);
    setError("");
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add Billing Item</DialogTitle>
      <DialogContent>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField label="Item" inputRef={item} fullWidth />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField
              label="Quantity"
              inputRef={qty}
              type="number"
              fullWidth
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <TextField label="Rate" inputRef={rate} type="number" fullWidth />
          </Grid2>
        </Grid2>
        {error && (
          <Typography color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={addBillingItem} variant="contained" color="primary">
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function BillingListDialog({ open, setOpen, bdata, setBdata }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`/api/billing`)
      .then((res) => res.json())
      .then((data) => setData(data))
      .catch(() => {});
  }, []);

  const addBillingItem = (item) => {
    setBdata([...bdata, item]);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Select Billing Item</DialogTitle>
      <DialogContent>
        <Box>
          {data.map((item, index) => (
            <Box
              key={index}
              onClick={() => addBillingItem(item)}
              sx={{ border: "1px solid #ddd", p: 2, cursor: "pointer", mb: 1 }}
            >
              <Typography variant="subtitle1">{item.Item}</Typography>
              <Typography>Qty: {item.Qty} pcs</Typography>
              <Typography>Rate: KSh {item?.Rate ? item?.Rate : 0}</Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
