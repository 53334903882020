import "../../Styles/requests.scss";
import AccordionBody from "../Util/AccordionBody";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import { BsEmojiSmile } from "react-icons/bs";
import WaveLoading from "../Util/WaveLoading";
import MyError from "../Util/MyError";
import AdminBody from "./AdminBody";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { MdAddTask } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  CircularProgress,
  ButtonGroup,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import jwt from "jsonwebtoken";

export default function Requests(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [active, setActive] = useState("New Jobs");
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(true);
  const [jobs, setJobs] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [limit, setLimit] = useState(10);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");
    if (token) {
      try {
        const decoded = jwt.decode(token);
        setUser(decoded.Name);
        if (!(decoded.Role === "Admin" || decoded.Role === "Super Admin")) {
          window.location.href = "/";
        }
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    if (searchValue == "") {
      if (active === "New Jobs") {
        fetchJobs("Received");
      } else if (active === "Active Jobs") {
        fetchJobs("Assigned");
      } else {
        fetchJobs("Complete");
      }
    }
  }, [changed, refresh, active, offset, limit, searchValue]);

  const fetchJobs = async (status) => {
    setJobs(null);
    setLoading(true);
    try {
      const res = await fetch(
        `/api/requests/admindata?type=${status}&offset=${
          offset * limit
        }&limit=${limit}`,
        {
          method: "get",
          credentials: "include",
        }
      );
      if (!res.ok) throw Error("Could not fetch messages!!!");
      const data = await res.json();
      setJobs(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSearch = async (value) => {
    if (value != "") {
      setSearchValue(value);
      setJobs(null);
      setLoading(true);
      setOffset(0);
      let status;
      if (active === "New Jobs") {
        status = "Received";
      } else if (active === "Active Jobs") {
        status = "Assigned";
      } else {
        status = "Complete";
      }
      try {
        const response = await fetch(
          `/api/requests/admindata?type=${status}&offset=${
            offset * limit
          }&limit=${limit}&column=${column}&searchValue=${value}`
        );
        const data = await response.json();
        if (response.ok) {
          setJobs(data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setSearchValue("");
      setChanged(!changed);
    }
  };

  return (
    <Box
      className="requests"
      p={2}
      component={Card}
      sx={{ boxShadow: "0px 8px 16px #60606040", borderRadius: 2 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="h5">BC Requests</Typography>
        <IconButton onClick={() => setRefresh(!refresh)}>
          <FiRefreshCcw className="refresh" />
        </IconButton>
      </Box>

      <Box display="flex" gap={1}>
        <ButtonGroup>
          <Selector txt="New Jobs" active={active} setActive={setActive} />
          <Selector txt="Active Jobs" active={active} setActive={setActive} />
          <Selector txt="Completed" active={active} setActive={setActive} />
        </ButtonGroup>
        <Box flexGrow={1}></Box>
        <FormControl sx={{ minWidth: { sx: "100%", md: "150px" } }}>
          <InputLabel size="small">Search by...</InputLabel>
          <Select
            label="Search by..."
            size="small"
            onChange={(e) => setColumn(e.target.value)}
            value={column}
          >
            <MenuItem value="Title">Title</MenuItem>
            <MenuItem value="Description">Description</MenuItem>
            <MenuItem value="Assigned">Assigned</MenuItem>
            <MenuItem value="ClientName">Client Name</MenuItem>
            <MenuItem value="Organisation">Organisation</MenuItem>
            <MenuItem value="ClientPhone">Client Phone</MenuItem>
            <MenuItem value="ClientEmail">Client Email</MenuItem>
            <MenuItem value="AssignedStaffName">Assigned Staff Name</MenuItem>
            <MenuItem value="AssignedStaffPhone">Assigned Staff Phone</MenuItem>
            <MenuItem value="AssignedStaffEmail">Assigned Staff Email</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Search..."
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>

      <Divider sx={{ my: 1 }} />

      <Box className="tcontent">
        {jobs && jobs?.data?.length > 0 ? (
          jobs.data.map((item, index) => (
            <AdminBody
              item={item}
              key={index}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          ))
        ) : (
          <MyError txt="No Active Tasks" />
        )}

        {jobs && jobs?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(jobs.total / 12)}
            currentPage={offset + 1}
            handlePageChange={(v) => setOffset(v - 1)}
          />
        )}
      </Box>

      {loading && <WaveLoading />}
    </Box>
  );
}

const Selector = (props) => {
  return (
    <Button
      variant={props.active === props.txt ? "contained" : "outlined"}
      color={props.active === props.txt ? "primary" : "default"}
      onClick={() => props.setActive(props.txt)}
      sx={{ textTransform: "capitalize" }}
    >
      {props.txt}
    </Button>
  );
};
