import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  CircularProgress,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSearch,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

export default function Invoicing(props) {
  const [data, setData] = useState(null);
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [column, setColumn] = useState("InvoiceNo");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    { field: "InvoiceNo", sort: "asc" },
  ]);

  useEffect(() => {
    if (props.user) {
      try {
        if (props.user.Role !== "Admin" && props.user.Role !== "Super Admin") {
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchInvoices = () => {
    setLoading(true);
    const orderColumn = sortModel[0]?.field || "InvoiceNo";
    const orderType = sortModel[0]?.sort || "asc";

    fetch(
      `/api/invoices/paginated?offset=${
        offset * limit
      }&limit=${limit}&orderColumn=${orderColumn}&orderType=${orderType}`,
      {
        method: "get",
        credentials: "include",
      }
    )
      .then((res) => res.ok && res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchInvoices();
  }, [offset, limit, searchValue, sortModel]);

  const handleSearch = async (value) => {
    setSearchValue(value);
    setData(null);
    setOffset(0);
    setLoading(true);
    try {
      const orderColumn = sortModel[0]?.field || "InvoiceNo";
      const orderType = sortModel[0]?.sort || "asc";
      const response = await fetch(
        `/api/invoices/paginated?offset=${
          offset * limit
        }&limit=${limit}&${column}=${value}&orderColumn=${orderColumn}&orderType=${orderType}`
      );
      const data = await response.json();
      if (response.ok) {
        setData(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (params) => {
    const invoiceId = params.row.ID;
    window.location.href = `/invoices/preview/${invoiceId}`;
  };

  const columns = [
    { field: "InvoiceNo", headerName: "No", width: 120 },
    { field: "Company", headerName: "Company", width: 250 },
    {
      field: "ContactPerson",
      headerName: "Contact",
      width: 150,
      renderCell: (params) => (
        <>
          <FontAwesomeIcon
            icon={faUser}
            style={{ marginRight: "10px", color: theme.palette.primary.main }}
          />
          {params.row.ContactPersonName} - {params.row.ContactPersonPhone} -{" "}
          {params.row.ContactPersonEmail}
        </>
      ),
    },
    { field: "Date", headerName: "Due Date" },
    {
      field: "Invoice",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          label={params.value ? "Sent" : "Not Sent"}
          color={params.value ? "primary" : "warning"}
        />
      ),
    },
    {
      field: "Balance",
      headerName: "Balance",
      renderCell: (params) => (
        <Box
          component="span"
          sx={{
            color: parseInt(params.row.Balance) <= 0 ? "green" : "orange",
            width: "100%",
            marginLeft: "auto",
            display: "block",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            textAlign: "right",
            fontSize: "medium",
          }}
        >
          {(params.row.Total - params.row.Balance).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) || 0}
        </Box>
      ),
    },
    {
      field: "Total",
      headerName: "Invoice Amount",
      width: 150,
      renderCell: (params) => (
        <Box
          component="span"
          sx={{
            color: params.row.Balance >= 0 ? "orangered" : "green",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            textAlign: "right",
            fontSize: "medium",
            fontWeight: "bold",
            marginLeft: "auto",
            display: "block",
          }}
        >
          {(params.value - 0).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 0 }}>
      <Card
        sx={{ boxShadow: "0px 16px 32px #60606040", borderRadius: "8px", p: 2 }}
      >
        <Box display="flex" gap={2} mb={2}>
          <Typography flexGrow={1} variant="h5" gutterBottom>
            Invoice List
          </Typography>
          <Box flexGrow={1}></Box>
          <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="InvoiceNo">Invoice No</MenuItem>
              <MenuItem value="Date">Date</MenuItem>
              <MenuItem value="Title">Title</MenuItem>
              <MenuItem value="Total">Total</MenuItem>
              <MenuItem value="Company">Company</MenuItem>
              <MenuItem value="Address">Address</MenuItem>
              <MenuItem value="ContactPersonName">Contact Person Name</MenuItem>
              <MenuItem value="ContactPersonPhone">
                Contact Person Phone
              </MenuItem>
              <MenuItem value="ContactPersonEmail">
                Contact Person Email
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", color: "white" }}
            startIcon={<FontAwesomeIcon icon={faUserPlus} />}
            onClick={() => (window.location.href = "/invoices/new")}
          >
            New Invoice
          </Button>
        </Box>

        {loading ? (
          <Box
            sx={{ display: "grid", placeContent: "center", minHeight: "30vh" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={data?.data || []}
            columns={columns}
            pageSize={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onPageSizeChange={(newLimit) => {
              setLimit(newLimit);
              setOffset(0);
            }}
            autoHeight
            disableSelectionOnClick
            onPageChange={(newPage) => setOffset(newPage)}
            pagination
            getRowId={(row) => row.ID}
            onSortModelChange={(model) => setSortModel(model)}
            onRowClick={handleRowClick}
          />
        )}
      </Card>
    </Box>
  );
}
